/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Kaip sprendžiau visokius tviterius mest per tvorą, taip metas mest ir\nžaidimukus. Jau nebe pirmą kartą, tiesa. Ale reikia vėlgi. Kažkaip\ndėmesio numetimas vis ", React.createElement(_components.em, null, "įkrypina"), " į mano gyvenimą ir kažkaip labai jau\nstipriai sujaukia viską ", React.createElement(_components.em, null, "naxujop"), "."), "\n", React.createElement(_components.p, null, "Kaip ir maždaug prieš metus, taip ir dabar: minčių padėjimas ant\npopieriaus yra vertinga veikla. Vakar pasikalbėjom su R apie visus ten\ndėmesio nukreipimo reikalus ir aš esu labai labai dėkingas už jos\n", React.createElement(_components.em, null, "approach'ą"), " į šitokias problemas ir aiškinimąsi."), "\n", React.createElement(_components.p, null, "Pats laikas vėl iš naujo susiimt ir skirti laiko sau, vietoj jo skyrimo\natsirašymui. Atsirašęs daug neparašysi, ", React.createElement(_components.em, null, "kai saka"), "."), "\n", React.createElement(_components.p, null, "Vakarop."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
